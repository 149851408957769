import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { MdLocationOn, MdEmail, MdPhone, MdDirectionsCar, MdBusiness, MdEdit } from 'react-icons/md';
import { FaRegMoneyBillAlt } from 'react-icons/fa';
import { BiTimeFive } from 'react-icons/bi';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import '../components/css/edit.css';
import Swal from 'sweetalert2';
import Spinner from './Spinner';

const TransportDetailsModal = ({ isOpen, onClose, transport, userToken }) => {
    const [pickupPhotos, setPickupPhotos] = useState([]);
    const [DropoffPhotos, setDropoffPhotos] = useState([]);
    const [carouselImages, setCarouselImages] = useState([]);
    const [isCarouselOpen, setIsCarouselOpen] = useState(false);
    const [editMode, setEditMode] = useState({});
    const [editedData, setEditedData] = useState({});
    const [currentTransport, setCurrentTransport] = useState(transport);
    const [drivers, setDrivers] = useState([]);
    const [loading, setLoading] = useState(false);
    const carouselRef = useRef(null);

    useEffect(() => {
        if (isOpen && transport) {
            fetchTransportData();
            fetchDrivers();
            setEditedData({});
        }
    }, [isOpen, transport]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (carouselRef.current && !carouselRef.current.contains(event.target)) {
                closeCarousel();
            }
        };

        if (isCarouselOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isCarouselOpen]);

    // const fetchTransportData = async () => {
    //     setLoading(true);
    //     try {
    //         const response = await axios.get(
    //             `${process.env.REACT_APP_API_PREFIX}/loadtransport/${transport.id}`,
    //             {
    //                 headers: {
    //                     "Screen-Name": "/NewLoad",
    //                     "Authorization": `Bearer ${userToken}`
    //                 }
    //             }
    //         );

    //         const updatedTransport = response.data.data;
    //         setCurrentTransport(updatedTransport);

    //         const loadItems = updatedTransport.load_items || [];
    //         const inspectionPhotos = loadItems.flatMap(item => item.inspection_photos || []);
    //         setPickupPhotos(inspectionPhotos.filter(photo => photo.inspection_type.toLowerCase() === 'pickup'));
    //         setDropoffPhotos(inspectionPhotos.filter(photo => photo.inspection_type.toLowerCase() === 'Dropoff'));
    //     } catch (error) {
    //         console.error("Erro ao buscar dados do backend:", error);
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    const fetchTransportData = async () => {
        console.log("Iniciando fetchTransportData..."); // Log inicial
        setLoading(true);

        try {
            console.log("Enviando solicitação para o backend..."); // Antes do request
            const response = await axios.get(
                `${process.env.REACT_APP_API_PREFIX}/loadtransport/${transport.id}`,
                {
                    headers: {
                        "Screen-Name": "/NewLoad",
                        "Authorization": `Bearer ${userToken}`
                    }
                }
            );

            console.log("Resposta recebida:", response); // Log da resposta completa

            const updatedTransport = response.data.data;
            console.log("Dados atualizados do transporte:", updatedTransport); // Dados de transporte atualizados

            setCurrentTransport(updatedTransport);

            const loadItems = updatedTransport.load_items || [];
            console.log("Itens de carga:", loadItems); // Itens de carga retornados

            const inspectionPhotos = loadItems.flatMap(item => item.inspection_photos || []);
            console.log("Fotos de inspeção:", inspectionPhotos); // Fotos de inspeção

            const pickupPhotos = inspectionPhotos.filter(photo => photo.inspection_type.toLowerCase() === 'pickup');
            const dropoffPhotos = inspectionPhotos.filter(photo => photo.inspection_type.toLowerCase() === 'dropoff');

            // console.log("Fotos de coleta:", pickupPhotos); 
            // console.log("Fotos de entrega:", dropoffPhotos); 

            setPickupPhotos(pickupPhotos);
            setDropoffPhotos(dropoffPhotos);
        } catch (error) {
            console.error("Erro ao buscar dados do backend:", error); // Log de erro detalhado
        } finally {
            setLoading(false);
            console.log("fetchTransportData concluído."); // Log final
        }
    };


    const fetchDrivers = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/driver`, {
                headers: {
                    "Screen-Name": "/NewLoad",
                    "Authorization": `Bearer ${userToken}`
                }
            });
            setDrivers(response.data.data);
        } catch (error) {
            console.error("Erro ao buscar drivers", error);
        }
    };

    const openCarousel = (photos) => {
        const images = photos.map(photo => ({
            original: `${process.env.REACT_APP_API_FILE_PREFIX}/${photo.path}`,
            thumbnail: `${process.env.REACT_APP_API_FILE_PREFIX}/${photo.path}`
        }));
        setCarouselImages(images);
        setIsCarouselOpen(true);
    };

    const closeCarousel = () => {
        setIsCarouselOpen(false);
    };

    const handleEditClick = (field) => {
        setEditMode((prev) => ({ ...prev, [field]: !prev[field] }));
        if (!editMode[field]) {
            setEditedData((prev) => ({ ...prev, [field]: currentTransport[field] }));
        }
    };

    const handleChange = (e, field) => {
        const value = e.target.value;
        setEditedData((prev) => ({ ...prev, [field]: value }));
    };

    const handleCancel = (field) => {
        setEditMode((prev) => ({ ...prev, [field]: false }));
        setEditedData((prev) => ({ ...prev, [field]: currentTransport[field] }));
    };

    const handleSave = async (field) => {
        try {
            const dataToSend = { [field]: editedData[field] };
            await axios.post(
                `${process.env.REACT_APP_API_PREFIX}/loadtransport/${currentTransport.id}`,
                dataToSend,
                {
                    headers: {
                        "Screen-Name": "/NewLoad",
                        "Authorization": `Bearer ${userToken}`
                    }
                }
            );
            setCurrentTransport((prev) => ({ ...prev, [field]: editedData[field] }));
            setEditMode((prev) => ({ ...prev, [field]: false }));
            Swal.fire({
                title: 'Success!',
                text: 'Information updated successfully!',
                icon: 'success',
                confirmButtonText: 'Ok'
            }).then(() => {
                window.location.href = '/Load';
            });
        } catch (error) {
            console.error("Erro ao atualizar dados:", error);
        }
    };

    if (!isOpen || !currentTransport) return null;

    return (
        <div className="fixed inset-0 flex justify-center items-start pt-10 z-50 bg-opacity-50" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
            <div className="bg-white w-full max-w-4xl mx-4 md:mx-8 p-8 rounded-lg shadow-xl overflow-auto transform transition-all duration-300 ease-out" style={{ fontFamily: 'Arial, sans-serif' }}>
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl font-semibold text-gray-800">Transport Details</h2>
                    <button onClick={onClose} className="text-gray-600 hover:text-gray-800 rounded p-2 focus:outline-none focus:ring-2 focus:ring-gray-400">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>

                {loading ? <Spinner /> : (
                    <div className="overflow-y-auto scrollbar-custom" style={{ maxHeight: '70vh' }}>
                        <div className="flex">
                            <div className="w-1/2 pr-4">
                                <Section title="General Information" icon={<FaRegMoneyBillAlt />}>
                                    <InfoRow
                                        label="Order ID"
                                        value={editedData.order_id ?? currentTransport.order_id}
                                        editMode={editMode.order_id}
                                        handleEditClick={() => handleEditClick('order_id')}
                                        handleChange={(e) => handleChange(e, 'order_id')}
                                        handleSave={() => handleSave('order_id')}
                                        handleCancel={() => handleCancel('order_id')}
                                        isEditable
                                    />
                                    <InfoRow
                                        label="Driver"
                                        value={editedData.driver_id ?? currentTransport.driver_id}
                                        editMode={editMode.driver_id}
                                        handleEditClick={() => handleEditClick('driver_id')}
                                        handleChange={(e) => handleChange(e, 'driver_id')}
                                        handleSave={() => handleSave('driver_id')}
                                        handleCancel={() => handleCancel('driver_id')}
                                        isEditable
                                        options={drivers}
                                    />
                                    <InfoRow
                                        icon={<BiTimeFive />}
                                        label="Dispatch Date"
                                        value={editedData.dispatch_date ?? currentTransport.dispatch_date}
                                        editMode={editMode.dispatch_date}
                                        handleEditClick={() => handleEditClick('dispatch_date')}
                                        handleChange={(e) => handleChange(e, 'dispatch_date')}
                                        handleSave={() => handleSave('dispatch_date')}
                                        handleCancel={() => handleCancel('dispatch_date')}
                                        isEditable
                                    />
                                    <InfoRow
                                        label="Loadboard"
                                        value={editedData.loadboard ?? currentTransport.loadboard}
                                        editMode={editMode.loadboard}
                                        handleEditClick={() => handleEditClick('loadboard')}
                                        handleChange={(e) => handleChange(e, 'loadboard')}
                                        handleSave={() => handleSave('loadboard')}
                                        handleCancel={() => handleCancel('loadboard')}
                                        isEditable
                                    />
                                    <InfoRow
                                        label="Payment Rate"
                                        value={editedData.payment_rate ?? currentTransport.payment_rate}
                                        editMode={editMode.payment_rate}
                                        handleEditClick={() => handleEditClick('payment_rate')}
                                        handleChange={(e) => handleChange(e, 'payment_rate')}
                                        handleSave={() => handleSave('payment_rate')}
                                        handleCancel={() => handleCancel('payment_rate')}
                                        isEditable
                                    />
                                    <InfoRow
                                        label="Payment Type"
                                        value={editedData.payment_type ?? currentTransport.payment_type}
                                        editMode={editMode.payment_type}
                                        handleEditClick={() => handleEditClick('payment_type')}
                                        handleChange={(e) => handleChange(e, 'payment_type')}
                                        handleSave={() => handleSave('payment_type')}
                                        handleCancel={() => handleCancel('payment_type')}
                                        isEditable
                                    />
                                    <InfoRow
                                        label="Payment Method"
                                        value={editedData.payment_method ?? currentTransport.payment_method}
                                        editMode={editMode.payment_method}
                                        handleEditClick={() => handleEditClick('payment_method')}
                                        handleChange={(e) => handleChange(e, 'payment_method')}
                                        handleSave={() => handleSave('payment_method')}
                                        handleCancel={() => handleCancel('payment_method')}
                                        isEditable
                                    />
                                </Section>
                                <Section title="Pickup Information" icon={<MdLocationOn />}>
                                    <AddressInfo
                                        transport={currentTransport}
                                        type="pickup"
                                        editMode={editMode}
                                        editedData={editedData}
                                        handleEditClick={handleEditClick}
                                        handleChange={handleChange}
                                        handleSave={handleSave}
                                        handleCancel={handleCancel}
                                    />
                                    <PhotoGallery photos={pickupPhotos} openCarousel={openCarousel} />
                                </Section>
                            </div>
                            <div className="w-1/2 pl-4">
                                <Section title="Vehicle Details" icon={<MdDirectionsCar />}>
                                    {currentTransport.load_items.map((item, index) => (
                                        <div key={index} className="mb-4">
                                            <InfoRow
                                                label="Make"
                                                value={editedData[`make_${index}`] ?? item.make}
                                                editMode={editMode[`make_${index}`]}
                                                handleEditClick={() => handleEditClick(`make_${index}`)}
                                                handleChange={(e) => handleChange(e, `make_${index}`)}
                                                handleSave={() => handleSave(`make_${index}`)}
                                                handleCancel={() => handleCancel(`make_${index}`)}
                                                isEditable
                                            />
                                            <InfoRow
                                                label="Model"
                                                value={editedData[`model_${index}`] ?? item.model}
                                                editMode={editMode[`model_${index}`]}
                                                handleEditClick={() => handleEditClick(`model_${index}`)}
                                                handleChange={(e) => handleChange(e, `model_${index}`)}
                                                handleSave={() => handleSave(`model_${index}`)}
                                                handleCancel={() => handleCancel(`model_${index}`)}
                                                isEditable
                                            />
                                            <InfoRow
                                                label="Year"
                                                value={editedData[`year_${index}`] ?? item.year}
                                                editMode={editMode[`year_${index}`]}
                                                handleEditClick={() => handleEditClick(`year_${index}`)}
                                                handleChange={(e) => handleChange(e, `year_${index}`)}
                                                handleSave={() => handleSave(`year_${index}`)}
                                                handleCancel={() => handleCancel(`year_${index}`)}
                                                isEditable
                                            />
                                            <InfoRow
                                                label="VIN"
                                                value={editedData[`vin_${index}`] ?? item.vin}
                                                editMode={editMode[`vin_${index}`]}
                                                handleEditClick={() => handleEditClick(`vin_${index}`)}
                                                handleChange={(e) => handleChange(e, `vin_${index}`)}
                                                handleSave={() => handleSave(`vin_${index}`)}
                                                handleCancel={() => handleCancel(`vin_${index}`)}
                                                isEditable
                                            />
                                        </div>
                                    ))}
                                </Section>
                                <Section title="Delivery Information" icon={<MdLocationOn />}>
                                    <AddressInfo
                                        transport={currentTransport}
                                        type="Dropoff"
                                        editMode={editMode}
                                        editedData={editedData}
                                        handleEditClick={handleEditClick}
                                        handleChange={handleChange}
                                        handleSave={handleSave}
                                        handleCancel={handleCancel}
                                    />
                                    <PhotoGallery photos={DropoffPhotos} openCarousel={openCarousel} />
                                </Section>
                            </div>
                        </div>
                        <Section title="Partner Company Information" icon={<MdBusiness />}>
                            <InfoRow label="Name" value={currentTransport.partner_company.partner_company_name} />
                            <InfoRow label="Phone" value={currentTransport.partner_company.phone} icon={<MdPhone />} />
                            <InfoRow label="Email" value={currentTransport.partner_company.email} icon={<MdEmail />} />
                            <InfoRow label="Address" value={currentTransport.partner_company.address} />
                        </Section>
                    </div>
                )}

                <div className="text-right mt-6">
                    <button onClick={onClose} className="py-2 px-4 text-sm font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200">
                        Close
                    </button>
                </div>
            </div>

            {isCarouselOpen && (
                <div className="fixed inset-0 z-50 bg-black bg-opacity-75 flex items-center justify-center">
                    <div ref={carouselRef} className="relative w-full max-w-4xl">
                        <button onClick={closeCarousel} className="absolute top-0 right-0 m-4 text-white text-2xl">×</button>
                        <ImageGallery items={carouselImages} />
                    </div>
                </div>
            )}
        </div>
    );
};

const Section = ({ title, children, icon }) => (
    <div className="mb-6">
        <h3 className="text-xl font-semibold flex items-center mb-4">
            {icon && <span className="mr-2 text-lg">{icon}</span>}
            {title}
        </h3>
        {children}
    </div>
);

const InfoRow = ({ icon, label, value, editMode, handleEditClick, handleChange, handleSave, handleCancel, isEditable, options }) => {
    const displayValue = options && value
        ? options.find(option => option.id === value)?.user?.first_name + ' ' + options.find(option => option.id === value)?.user?.last_name
        : value;

    return (
        <div className="flex items-center mb-2">
            {icon && <div className="mr-2">{icon}</div>}
            <div className="font-medium mr-2"><strong>{label}</strong>:</div>
            {editMode ? (
                <div className="flex items-center">
                    {options ? (
                        <select
                            className="border p-1 mr-2"
                            value={value || ""}
                            onChange={handleChange}
                        >
                            <option value="">No driver assigned</option>
                            {options.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.user ? `${option.user.first_name} ${option.user.last_name}` : 'Unknown Driver'}
                                </option>
                            ))}
                        </select>
                    ) : (
                        <input
                            type="text"
                            className="border p-1 mr-2"
                            value={value}
                            onChange={handleChange}
                        />
                    )}
                    <button onClick={handleSave} className="text-blue-500 mr-2">Save</button>
                    <button onClick={handleCancel} className="text-red-500">Cancel</button>
                </div>
            ) : (
                <div className="flex items-center">
                    <span>{displayValue}</span>
                    {isEditable && <MdEdit className="ml-2 cursor-pointer" onClick={handleEditClick} />}
                </div>
            )}
        </div>
    );
};

const AddressInfo = ({ transport, type, editMode, editedData, handleEditClick, handleChange, handleSave }) => (
    <>
        <InfoRow
            label="Address"
            value={editedData[`${type}_address`] ?? transport[`${type}_address`]}
            editMode={editMode[`${type}_address`]}
            handleEditClick={() => handleEditClick(`${type}_address`)}
            handleChange={(e) => handleChange(e, `${type}_address`)}
            handleSave={() => handleSave(`${type}_address`)}
            isEditable
        />
        <InfoRow
            label="City"
            value={editedData[`${type}_city`] ?? transport[`${type}_city`]}
            editMode={editMode[`${type}_city`]}
            handleEditClick={() => handleEditClick(`${type}_city`)}
            handleChange={(e) => handleChange(e, `${type}_city`)}
            handleSave={() => handleSave(`${type}_city`)}
            isEditable
        />
        <InfoRow
            label="State"
            value={editedData[`${type}_state`] ?? transport[`${type}_state`]}
            editMode={editMode[`${type}_state`]}
            handleEditClick={() => handleEditClick(`${type}_state`)}
            handleChange={(e) => handleChange(e, `${type}_state`)}
            handleSave={() => handleSave(`${type}_state`)}
            isEditable
        />
        <InfoRow
            label="Zipcode"
            value={editedData[`${type}_zipcode`] ?? transport[`${type}_zipcode`]}
            editMode={editMode[`${type}_zipcode`]}
            handleEditClick={() => handleEditClick(`${type}_zipcode`)}
            handleChange={(e) => handleChange(e, `${type}_zipcode`)}
            handleSave={() => handleSave(`${type}_zipcode`)}
            isEditable
        />
        <InfoRow
            label="Customer Name"
            value={editedData[`${type}_customer_name`] ?? transport[`${type}_customer_name`]}
            editMode={editMode[`${type}_customer_name`]}
            handleEditClick={() => handleEditClick(`${type}_customer_name`)}
            handleChange={(e) => handleChange(e, `${type}_customer_name`)}
            handleSave={() => handleSave(`${type}_customer_name`)}
            isEditable
        />
        <InfoRow
            label="Customer Phone"
            value={editedData[`${type}_customer_phone`] ?? transport[`${type}_customer_phone`]}
            icon={<MdPhone />}
            editMode={editMode[`${type}_customer_phone`]}
            handleEditClick={() => handleEditClick(`${type}_customer_phone`)}
            handleChange={(e) => handleChange(e, `${type}_customer_phone`)}
            handleSave={() => handleSave(`${type}_customer_phone`)}
            isEditable
        />
        <InfoRow
            label="Customer Email"
            value={editedData[`${type}_customer_email`] ?? transport[`${type}_customer_email`]}
            icon={<MdEmail />}
            editMode={editMode[`${type}_customer_email`]}
            handleEditClick={() => handleEditClick(`${type}_customer_email`)}
            handleChange={(e) => handleChange(e, `${type}_customer_email`)}
            handleSave={() => handleSave(`${type}_customer_email`)}
            isEditable
        />
    </>
);

const PhotoGallery = ({ photos, openCarousel }) => (
    <div className="flex flex-wrap justify-center">
        {photos.length > 0 ? photos.map((photo, index) => (
            <div
                key={index}
                className="w-full bg-gray-50 dark:bg-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors duration-200 rounded-lg shadow-lg m-2"
                onClick={() => openCarousel(photos)}
            >
                <img
                    src={`${process.env.REACT_APP_API_FILE_PREFIX}/${photo.path}`}
                    alt={`Inspection ${index + 1}`}
                    className="w-full h-32 object-cover cursor-pointer rounded-lg shadow-lg hover:shadow-xl transform hover:scale-105 transition-transform duration-200 ease-out"
                />
            </div>
        )) : (
            <>
                <div className="flex items-center p-4 border-b border-gray-200 dark:border-gray-700 w-full">
                    <h2 className="text-lg font-semibold ml-4 text-gray-900 dark:text-white">Photos of Vehicle</h2>
                </div>
                <div className="w-full bg-gray-50 dark:bg-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors duration-200 rounded-lg shadow-lg p-4 m-2 flex justify-center items-center">
                    <p className="text-gray-600 dark:text-gray-400">No photos available</p>
                </div>
            </>
        )}
    </div>
);

export default TransportDetailsModal;